import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  BackIcon,
  CallsInsightsImage,
  CallTypeLabel,
  EmptyInsights,
  THQAppBar,
  THQPrimaryButton,
  THQSelectSearch,
  useDebounce
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import { SelectedRPInsightsTabContext, SelectedTypeOption } from '@/components/insights/role-play/context';
import { MainGridStyled } from '@/components/insights/styles';
import { CALLS_INSIGHTS_STARTER, INSIGHTS } from '@/constants/router';
import { useGetCallTypesPage } from '@/hooks/callIntelligence/useCallIntelligenceHooks';

const CallsInsightsStarter: React.FC = () => {
  const { t } = useTranslation();
  const { callTypeUuid } = useParams<{ callTypeUuid: string }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [callTypeFilter, setCallTypeFilter] = useState<string>('-');
  const [selectedCallType, setSelectedCallType] = useState<CallTypeLabel>();

  const {
    loadedItems: callTypeLoadedItems,
    loadMore: callTypeLoadMore,
    search: callTypeSearch
  } = useGetCallTypesPage({
    page: 0,
    size: 5
  });

  const urlCallTypeNotInOptions =
    callTypeUuid && !callTypeLoadedItems?.find((callType) => callType.uuid === callTypeUuid);

  const getCallTypeFromUrlCoordinator = useGetCallTypesPage({
    page: 0,
    size: 1,
    criterias: [
      {
        key: 'uuid',
        operation: 'equals',
        relationType: 'AND',
        value: callTypeUuid
      }
    ]
  });

  const callTypeFromUrl = useMemo(
    () => getCallTypeFromUrlCoordinator?.loadedItems[0],
    [getCallTypeFromUrlCoordinator?.loadedItems]
  );

  const handleOnChangeCallType = useCallback(
    (event) => {
      navigate(event.target.value === '-' ? CALLS_INSIGHTS_STARTER : `${CALLS_INSIGHTS_STARTER}/${event.target.value}`);
      setCallTypeFilter(event.target.value);
      setSelectedCallType(callTypeLoadedItems.find((rp) => rp.uuid === event.target.value));
    },
    [navigate, callTypeLoadedItems]
  );

  const handleCallTypeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      callTypeSearch([
        {
          key: 'name',
          operation: 'icontains',
          relationType: 'AND',
          value: event.target.value
        }
      ]);
    },
    [callTypeSearch]
  );

  const handleOnOpenCallTypeFilter = useCallback(() => {
    callTypeSearch([{ key: 'name', operation: 'icontains', relationType: 'AND', value: '' }]);
  }, [callTypeSearch]);

  const debouncedCallTypeSearch = useDebounce(500, handleCallTypeSearch);

  const callTypeOptions = useMemo(
    () =>
      [
        { value: '-', label: t('common.rp_challenge_none') },
        ...(urlCallTypeNotInOptions && selectedCallType
          ? [
              {
                value: selectedCallType?.uuid,
                label: selectedCallType?.name
              }
            ]
          : []),
        ...(callTypeLoadedItems?.length === 0
          ? []
          : callTypeLoadedItems.map((item) => ({
              value: item.uuid,
              label: item.name
            })))
      ]?.filter((option) => !!option),
    [callTypeLoadedItems, selectedCallType, t, urlCallTypeNotInOptions]
  );

  const contextValue = useMemo(
    () => ({
      selectedRolePlay: selectedCallType?.defaultEvaluationRolePlay,
      selectedTypeOption: SelectedTypeOption.CALLS
    }),
    [selectedCallType?.defaultEvaluationRolePlay]
  );

  useEffect(() => {
    if (callTypeUuid && callTypeFromUrl) {
      setCallTypeFilter(callTypeUuid);
      setSelectedCallType(
        callTypeLoadedItems?.find((rp) => rp.uuid === callTypeUuid) || getCallTypeFromUrlCoordinator?.loadedItems?.[0]
      );
    }
  }, [
    getCallTypeFromUrlCoordinator?.loadedItems,
    callTypeFromUrl,
    callTypeUuid,
    callTypeLoadedItems,
    searchParams,
    selectedCallType
  ]);

  const filters = useMemo(
    () => (
      <Grid item xs={12} sm="auto">
        <Grid alignItems="center" container columnSpacing={{ xs: 0, sm: 1 }} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm="auto">
            <THQSelectSearch
              label={t('call_type')}
              options={callTypeOptions}
              searchPlaceholder={t('search_calls')}
              value={callTypeFilter}
              onChange={handleOnChangeCallType}
              onLoadMore={callTypeLoadMore}
              onOpen={handleOnOpenCallTypeFilter}
              onSearch={debouncedCallTypeSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    ),
    [
      debouncedCallTypeSearch,
      handleOnChangeCallType,
      handleOnOpenCallTypeFilter,
      callTypeFilter,
      callTypeOptions,
      callTypeLoadMore,
      t
    ]
  );

  const handleGoBack = useCallback(() => {
    navigate(INSIGHTS);
  }, [navigate]);

  // Handles case when user clicks on sidebar menu item
  useEffect(() => {
    if (!callTypeUuid) {
      setCallTypeFilter('-');
    }
  }, [callTypeUuid]);

  return (
    <SelectedRPInsightsTabContext.Provider value={contextValue}>
      {isTablet ? (
        <THQAppBar
          backButton={
            <THQPrimaryButton sx={{ marginRight: '8px' }} onClick={handleGoBack}>
              <BackIcon />
            </THQPrimaryButton>
          }
          text={t('insights')}
        >
          {filters}
        </THQAppBar>
      ) : (
        <MainGridStyled alignItems="center" container justifyContent="space-between" rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm="auto">
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <THQPrimaryButton onClick={handleGoBack}>
                  <BackIcon />
                </THQPrimaryButton>
              </Grid>
              <Grid item>
                <Typography>
                  {t('insights')} / {t('calls')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {filters}
        </MainGridStyled>
      )}
      {callTypeFilter === '-' ? (
        <EmptyInsights
          image={CallsInsightsImage}
          mainText={t('insights_select_calls_main')}
          subtext={t('insights_select_calls_sub')}
        />
      ) : (
        <Outlet />
      )}
    </SelectedRPInsightsTabContext.Provider>
  );
};

export default CallsInsightsStarter;
