export const HOME = '/';

export const LOGIN = '/auth/login';
export const SET_UP_ACCOUNT = '/auth/set-up-account';
export const TOKEN_AUTH = '/auth/token-auth';

export const RESET_PASSWORD = '/auth/reset-password';
export const RESET_PASSWORD_EMAIL = '/auth/reset-password/email';
export const RESET_PASSWORD_SMS = '/auth/reset-password/sms';
export const RESET_PASSWORD_SMS_VERIFY = '/auth/reset-password/sms/verify';
export const CONFIRM_PASSWORD = '/auth/confirm-password';
export const VALIDATE_SMS_TOKEN = 'learner/user/validateToken';

export const ROLEPLAYS = '/roleplays';
export const ROLE_PLAY_STRING = '/role-play';
export const ROLE_PLAY = `${ROLE_PLAY_STRING}/:rolePlayUuid`;
export const ROLEPLAY_START = `${ROLE_PLAY}/start`;
export const ROLEPLAY_DETAILS = `/roleplay-details`;

export const COURSES = '/courses';

export const JOURNEYS = '/journeys';
export const JOURNEY_STRING = '/journey';
export const JOURNEY = `${JOURNEY_STRING}/:journeyUuid`;
export const FEEDBACK = '/feedback';

export const COURSE_DETAILS_STRING = '/course';
export const BLOCK_STRING = '/block';
export const COURSE_DETAILS = `${COURSE_DETAILS_STRING}/:courseUuid`;
export const BLOCK = `${COURSE_DETAILS}${BLOCK_STRING}/:blockUuid`;

export const LIBRARY = '/library';

export const LIBRARY_OVERVIEW_STRING = 'library-overview';
export const LIBRARY_OVERVIEW = `/${LIBRARY_OVERVIEW_STRING}/*`;

export const INSIGHTS = '/insights';
export const ROLEPLAY_INSIGHTS_STARTER = `${INSIGHTS}/role-play`;
export const CALLS_INSIGHTS_STARTER = `${INSIGHTS}/calls`;
export const ROLE_PLAY_INSIGHTS = `${ROLEPLAY_INSIGHTS_STARTER}/:rolePlayUuid`;
export const CALLS_INSIGHTS = `${CALLS_INSIGHTS_STARTER}/:callTypeUuid`;
export const NATURAL_ROLE_PLAY_SCORECARD = `${ROLE_PLAY_INSIGHTS}/:reportUuid`;

export const CALLS = '/calls';
export const CALL_SCORECARD = `${CALLS}/:callUuid`;
